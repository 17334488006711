forms-tags-input {
  box-shadow: none;
  border: none;
  padding: 0;
  min-height: 34px;
}
forms-tags-input .host {
  margin: 0;
}
forms-tags-input .tags {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid #ccc;
}
forms-tags-input .tags .tag-item {
  color: #fff;
  background: #428bca;
  border: 1px solid #357ebd;
  border-radius: 4px;
}
forms-tags-input .tags .tag-item.selected {
  color: #fff;
  background: #d9534f;
  border: 1px solid #d43f3a;
}
forms-tags-input .tags .tag-item .remove-button:hover {
  text-decoration: none;
}
forms-tags-input .autocomplete {
  border-radius: 4px;
}
forms-tags-input .autocomplete .suggestion-item.selected {
  color: #262626;
  background-color: #f5f5f5;
}
forms-tags-input .autocomplete .suggestion-item.selected em {
  color: #262626;
  background-color: #f5f5f5;
}
forms-tags-input .autocomplete .suggestion-item em {
  color: #000;
  background-color: #fff;
}
forms-tags-input.ng-invalid .tags {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}
forms-tags-input[disabled] .tags {
  background-color: #eee;
}
forms-tags-input[disabled] .tags .tag-item {
  background: #337ab7;
  opacity: 0.65;
}
forms-tags-input[disabled] .tags .input {
  background-color: #eee;
}

.input-group forms-tags-input {
  padding: 0;
  display: table-cell;
}
.input-group forms-tags-input:not(:first-child) .tags {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group forms-tags-input:not(:last-child) .tags {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-lg forms-tags-input:first-child .tags {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.input-group-lg forms-tags-input:last-child .tags {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.input-group-sm forms-tags-input:first-child .tags {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.input-group-sm forms-tags-input:last-child .tags {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

forms-tags-input.ti-input-lg, .input-group-lg forms-tags-input {
  min-height: 46px;
}
forms-tags-input.ti-input-lg .tags, .input-group-lg forms-tags-input .tags {
  border-radius: 6px;
}
forms-tags-input.ti-input-lg .tags .tag-item, .input-group-lg forms-tags-input .tags .tag-item {
  height: 38px;
  line-height: 37px;
  font-size: 18px;
  border-radius: 6px;
}
forms-tags-input.ti-input-lg .tags .tag-item .remove-button, .input-group-lg forms-tags-input .tags .tag-item .remove-button {
  font-size: 20px;
}
forms-tags-input.ti-input-lg .tags .input, .input-group-lg forms-tags-input .tags .input {
  height: 38px;
  font-size: 18px;
}
forms-tags-input.ti-input-sm, .input-group-sm forms-tags-input {
  min-height: 30px;
}
forms-tags-input.ti-input-sm .tags, .input-group-sm forms-tags-input .tags {
  border-radius: 3px;
}
forms-tags-input.ti-input-sm .tags .tag-item, .input-group-sm forms-tags-input .tags .tag-item {
  height: 22px;
  line-height: 21px;
  font-size: 12px;
  border-radius: 3px;
}
forms-tags-input.ti-input-sm .tags .tag-item .remove-button, .input-group-sm forms-tags-input .tags .tag-item .remove-button {
  font-size: 16px;
}
forms-tags-input.ti-input-sm .tags .input, .input-group-sm forms-tags-input .tags .input {
  height: 22px;
  font-size: 12px;
}

.has-feedback forms-tags-input .tags {
  padding-right: 30px;
}

.has-success forms-tags-input .tags {
  border-color: #3c763d;
}
.has-success forms-tags-input .tags.focused {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.has-error forms-tags-input .tags {
  border-color: #a94442;
}
.has-error forms-tags-input .tags.focused {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-warning forms-tags-input .tags {
  border-color: #8a6d3b;
}
.has-warning forms-tags-input .tags.focused {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}
